$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.sectionLabel {
  display: inline-block;
  color: $secondary-600;
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.customers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  .option {
    border: 1px solid $secondary-400;
    border-radius: 0.5rem;
    margin: 0;
    padding: 0.75rem 1rem;
    background: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: $secondary-100;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px $secondary-900;
    }

    .infoDetails {
      display: flex;
      flex-direction: column;
    }
    .subtitle {
      color: $secondary-600;
    }
  }
}
