$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

.assignmentSearch {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  .searchIcon {
    &.expanded {
      display: none;
    }
  }

  .searchForm {
    width: 0;
    transition: width 0.3s ease-in-out, border-width 0.3s ease-in-out, padding 0.3s ease-in-out;

    input {
      padding: 0;
      border-width: 0;
    }

    &.expanded {
      width: 10rem;
      input {
        padding: 0.5rem;
        border-width: 1px;
      }
    }
  }
}
