$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.goBack {
  position: absolute;
  top: 1rem;
  padding: 0 !important;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: $secondary-900;
  vertical-align: middle;
}

.subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.drawerTitleTag {
  padding-left: 8px;
}
