$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

:global(.modal-content) {
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

div.modal {
  .modalHeader {
    padding-bottom: 0;
    color: $secondary-900;
  }

  .modalBody {
    padding-bottom: 0;

    &.hasDescription {
      padding-top: 0;

      .description {
        font-weight: 400;
        color: $secondary-600;
        padding-top: 0.5rem;
        margin-bottom: 2rem;
      }
    }
  }

  .modalFooter {
    display: flex;
    gap: 1rem;
  }

  .noModalFooter {
    padding-top: 0;
  }
}
