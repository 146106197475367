$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.subtaskSection {
  padding: 2rem;
  height: 100%;
  overflow-y: scroll;

  .subtaskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtaskTitle {
      display: flex;
      gap: 0.5rem;
      font-size: 2.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .subtaskDetails {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .secondaryValue {
        color: $secondary-600;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
  .row {
    margin-bottom: 2.5rem;
  }
  .subtaskId {
    display: flex;
    gap: 0.5rem;
    color: $secondary-600;
    font-weight: 400;
  }

  .subtaskInfo {
    display: flex;
    margin-top: 1.5rem;

    .infoItem {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding-right: 1.5rem;

      &:not(:first-child) {
        border-left: 1px solid $secondary-200;
        padding-left: 1.5rem;
      }

      .label {
        color: $secondary-600;
        font-weight: 400;
      }

      .value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 400;
      }

      .shortNotice {
        border: 1px solid $secondary-300;
        border-radius: $border-radius-sm;
        font-size: 0.875rem;
        padding: 2px 4px;
        margin-left: 0.5rem;
      }
    }
  }

  .subtaskDeadlineChangeRequest {
    margin-top: 3rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.1235rem;
      font-weight: 600;

      span,
      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .container {
      margin-top: 1rem;
    }
  }

  .actions {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    gap: 1rem;
  }

  .section {
    margin-top: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-weight: 400;
    height: 100%;
    
    .card {
      display: grid;
      position: relative;
      margin-top: 1rem;
      border: 1px solid $secondary-200;
      border-radius: $border-radius-md;
      padding: 0.75rem 1rem;
      height: 100%;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        align-self: start;

        &:not(:first-of-type) {
          margin-top: 1.5rem;
        }

        .label {
          color: $secondary-600;
        }

        .value {
          display: block;
        }
      }

      .actions {
        margin-top: 0;
        align-self: end;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      background-color: $secondary-50;
      border-radius: $border-radius-md;
      margin-top: 1.25rem;
      height: 100%;

      &.yellow {
        background-color: $warning-100;
      }

      &.green {
        background-color: $success-100;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.875rem;

        div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .assignButton {
          display: flex;
          padding: 0;
        }

        .assignButtonIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          border: 1px dashed $secondary-400;
          border-radius: 50%;
          cursor: pointer;
        }

        .assignedSeller {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }

    .loadingBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .spinner {
        height: 2rem;
        width: 2rem;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.1235rem;
      font-weight: 600;

      span,
      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .icon {
      font-size: 1rem;
    }
  }
}
