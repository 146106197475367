$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/colours.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;

  h1 {
    font-size: 2.25rem;
    color: $secondary-900;
    margin: unset;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }

  .tabsContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    border-bottom: 1px solid $secondary-200;

    // Remove unused tab-content provided by library
    div {
      display: none;
    }
  }

  .tableContainer {
    margin-top: 1rem;
    overflow-x: scroll;
    overflow: visible;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .loadMoreButton {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}

.exportDrawerContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .datePicker {
    width: fit-content;
    min-width: 18rem;
  }

  .exportButton {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      button {
        width: 100%;
      }
    }
  }
}
