$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .phoneInputs,
  .nameInputs {
    display: flex;
    gap: 2rem;

    & > * {
      width: 100%;
    }
  }

  .phoneInputs {
    & > :first-child {
      max-width: 7rem;
    }
  }
  .editRoleHeader {
    color: $secondary-900;
  }

  .toggleItems {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    span {
      color: $secondary-600;
      font-weight: 400;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .buttonLink {
    padding: 0;
  }
}
