$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.regexHint {
  margin-top: 0.25rem;
  display: flex;
  gap: 0.125rem;

  > svg {
    margin-top: 0.125rem;
  }
}

.hintText {
  font-size: 0.875rem;
  color: $secondary-600;
  font-weight: 400;
  margin-bottom: 0;
}

.warning {
  color: $warning-600;
}

.success {
  color: $success-600;
}
