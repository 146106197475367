$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.container {
  height: 100vh;
  width: 100%;
  background-color: $background-color;

  .row {
    height: 100%;
  }

  .imageView {
    padding: 0;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .heroImage {
      width: 100%;
      height: 100%;
      background-image: url('./auth-hero.png');
      background-size: cover;
    }
  }

  .formContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .formContent {
      max-width: 31rem;
      border-radius: $border-radius-lg;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    }
  }
}
