$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

a.menuItem {
  display: flex;
  padding: 0.5rem;

  &:visited {
    color: $secondary-700;
    background-color: $white;
  }

  &:active {
    color: unset;
  }
  &:focus-visible {
    outline: 2px solid crimson;
  }

  &:focus,
  &:hover {
    background-color: $secondary-50;
  }

  &.disabled {
    color: $secondary-300;

    .description {
      display: block;
      color: $secondary-300;
    }

    .icon {
      color: $secondary-300;
    }
  }

  & > :not(:first-of-type) {
    margin-left: 0.5rem;
  }

  .icon {
    color: $secondary-700;
  }

  .name {
    display: block;
    font-weight: 600;
  }

  .description {
    display: block;
    color: $secondary-500;
    word-wrap: normal;
    white-space: normal;
  }
}
