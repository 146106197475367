$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.skeleton {
  width: 15rem;
}

.description {
  display: block;
  margin-top: 1rem;
}

.values {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.descriptionValues {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 1rem;
}

.descriptionOldValueWrapper {
  width: 55%;
  display: flex;
  align-items: center;
}

.descriptionOldValue {
  max-width: 100%;
  width: 90%;
  text-align: left;
}

.descriptionValue {
  width: 45%;
  text-align: left;
}

.descriptionArrow {
  width: 10%;
  display: flex;
  justify-content: center;
}

.descriptionArrow,
.arrow {
  font-size: 0.75rem;
}
