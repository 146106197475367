$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sectionItemContent {
  margin-top: 2rem;
  
  .errorMessage {
    color: $error-600;
    display: block;
  
    & > :not(:first-child) {
      margin-left: 0.25rem;
    }
  }
}

.emailInputRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.emailInput {
  width: 50%;
}

.emailList {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.emailItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}


.accessLevel {
  width: 30%;
}
