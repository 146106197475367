$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.requirementsSection {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;

  .resetButton {
    font-size: 1rem;
    font-weight: 500;
    color: $secondary-900;
    padding: 0;
  }
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.spaceBetween {
  justify-content: space-between;
  width: 100%;
}

.clockIcon {
  color: $warning-600;
}

.exclamationMarkIcon {
  color: $error-600;
}
