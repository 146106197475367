$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.iconButton:is(a, button) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: $secondary-700;
  border: none;
  border-radius: 50%;

  &.active:active,
  &:hover {
    background-color: $secondary-100 !important;
    color: $secondary-700;
  }

  &[disabled] {
    background-color: transparent;

    color: $secondary-500;
  }

  &.small {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.875rem;
  }

  &.medium {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1rem;
  }

  &.large {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.25rem;
  }

  &.x-large {
    width: 3.25rem;
    height: 3.25rem;
    font-size: 1.5rem;
  }
}
.spinner {
  margin-right: 0.5rem;
}
