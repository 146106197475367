$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.card {
  list-style: none;

  .linkButton {
    padding: 0;
    font-weight: 500;

    &--underline {
      span {
        text-decoration: underline;
      }
    }
  }
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.125rem;
}

.cardTitle {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;

  svg {
    height: 1rem;
    width: 1rem;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid $secondary-200;
}

.cardBodyItem {
  &--warning {
    background-color: $warning-100;
    box-shadow: 0 0 0 0.5rem $warning-100;
    border-radius: 0.25rem;
  }

  &--error {
    background-color: $error-100;
    box-shadow: 0 0 0 0.5rem $error-100;
    border-radius: 0.25rem;
  }
}

.cardBodyIcon {
  &--warning {
    color: $warning-600;
  }

  &--error {
    color: $error-600;
  }
}

.emptyCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-weight: 400;

  svg {
    height: 3rem;
    width: 3rem;
    color: $secondary-600;
  }
}

.itemTitle {
  color: $secondary-600;
}

.itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  margin: 0;
}

.itemRow {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.separator {
  margin: 0;
  opacity: 1;
  color: $secondary-200;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
