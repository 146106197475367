$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.fileUploader {
  margin-top: 2rem;
}

.returnAddressLabel {
  display: block;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 2rem;
  font-weight: 600;
}

.coverLetterHasAddressCheckbox {
  margin: 2rem 0;
}

.returnAddressFormWrapper {
  margin-top: 2rem;

  .returnAddressFormFirstRow {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;

    .recipientName {
      width: 40%;
    }
    .enterpriseName {
      width: 60%;
    }
  }
}

.coverLetterLabel,
.coverLetterErrorMessage {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
}

.coverLetterLabel {
  color: $secondary-900;
  margin-bottom: 0.25rem;
}

.coverLetterErrorMessage {
  color: $error-600;
  display: flex;
  gap: 0.25rem;
}
