$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.container {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  .icon {
    margin: 0.1rem;
    height: 1rem;
    width: 1rem;
  }

  &.error {
    color: $error-600;
  }

  &.warning {
    color: $secondary-600;
    .icon {
      color: $warning-600;
    }
  }
}
