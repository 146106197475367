$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.body {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.25rem 1rem;
  border: 1px solid $secondary-200;
  border-radius: 0.5rem;
}

.hr {
  height: 1px;
  background-color: $secondary-200;
  margin: 0;
  padding: 0;
}

.rowItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1rem;

  .rowItemTitle {
    color: $secondary-600;
  }

  .rowItemBody {
    color: $secondary-900;
  }
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  gap: 1rem;
}
