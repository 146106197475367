$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

$gap: 1.5rem;

.title {
  font-size: 0.875rem;
  font-weight: 700;
  color: $secondary-900;
}

.hint {
  font-size: 0.875rem;
  font-weight: 400;
  color: $secondary-600;
}

.gridRow {
  display: grid;
  gap: $gap;
}

.postcodeRow {
  grid-template-columns: 35% auto;
}

.evenRow {
  grid-template-columns: repeat(2, 1fr);
}

.contactRow {
  grid-template-columns: 50% 15% 1fr;
}

.addressRow {
  grid-template-columns: 1fr max-content;

  .addAddress {
    background-color: $white;
  }
}

.videoWrapper {
  background-color: $white;
  padding: $gap;
  border-radius: 0.5rem;
  border: 1px solid $secondary-400;
}

.savedAddresses {
  font-weight: 500;
}
