$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.accordion {
  color: $secondary-900;

  .accordionHeader {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;

    h1 {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .accordionBody {
    background-color: $secondary-50;
    color: $secondary-900;
    margin-top: 1rem;
    padding: 1rem;
    padding-left: 1.9rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0;
    }
  }
}
