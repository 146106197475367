$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.overlayCardWrapper {
  min-width: max-content;
  padding-bottom: 0.75rem;
}

.overlayCard {
  width: max-content;
  border-radius: 0.5rem;
  border: 1px solid $secondary-200;
  background-color: $white;
  box-shadow: 0px 10px 24px -4px #1018281a, 0px 0px 8px -4px #1018280d;
}

.bookerHoverCardTop {
  padding: 1rem 1.25rem;
  max-width: 30rem;
  width: 100vw;
  border-bottom: 1px solid $secondary-200;
}

.bookerHoverCardIconRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
}

.bookerHoverCardAvatar {
  margin: 0 auto 0 0;
}

.bookerHoverCardAvatarLoader {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

.bookerHoverCardActionBtnLoader {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin-left: 1.25rem;
}

.bookerHoverCardLargeLoader {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
}

.bookerHoverCardBottom {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  gap: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  &--is-centered {
    justify-content: center;
  }
}

.bookerHoverCardFullName {
  font-weight: 600;
  color: $secondary-900;
}

.bookerHoverCardTag {
  margin: 0 0 0 auto;
}
