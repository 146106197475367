$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.label {
  color: $secondary-900;
  font-weight: 400;
  font-size: 0.875rem;
}

.addNewButton,
.newOption {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 14rem;
  padding: 3rem;
  margin-top: 1.5rem;
  margin-left: 1rem;
  border: 1px dashed $secondary-400;
  border-radius: $border-radius-md;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    border-color: $secondary-900;
  }
}

.addNewButton {
  margin-top: 0.5rem;
  width: 100%;
  margin-left: 0;
}

.paymentMethods {
  display: flex;
  width: 100%;
}

.dropdownPaymentMethods {
  .selectorWithButton {
    display: flex;
    width: 100%;
    gap: 0.5rem;
  }

  .selector {
    width: 100%;
  }

  .button {
    min-width: 10rem;
  }
}
