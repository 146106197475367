$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
  color: $secondary-900;
}

button.dropdownToggle {
  &:global(.btn) {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: 0;

    &:focus,
    &[aria-expanded='true'],
    &:focus-visible,
    &:active,
    &:hover {
      background-color: transparent;
    }
  }
}

div.dropdownMenu {
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  padding-top: 1rem;
  overflow: hidden;
  padding-bottom: 1rem;
  min-width: 100%;

  &.hasSelection {
    padding-bottom: 3rem;
  }

  .dropDownItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: $border-radius-sm;
  }

  .customIcon {
    display: flex;
    align-items: center;
  }

  .critical {
    color: $error-600;
  }

  .inactive {
    color: $secondary-300;
  }

  .clearSelection {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0rem 0.5rem;
    background-color: $secondary-50;
  }
}
