$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.tag {
  span {
    font-weight: 400;
    line-height: 1.5rem;
    padding-block: 0.25rem;
    border-radius: 3.125rem;
    color: $secondary-900;
  }

  &.success {
    background-color: $success-300;
  }

  &.error {
    background-color: $error-300;
  }

  &.info {
    background-color: $info-300;
  }

  &.warning {
    background-color: $warning-300;
  }

  &.primary {
    background-color: $primary-300;
  }

  &.neutral {
    border: 1px solid $secondary-300;
  }

  &.small {
    padding-block: 0;
    padding-inline: 0.25rem;
    font-size: 0.875rem;
  }

  &.medium {
    padding-inline: 0.5rem;
  }

  &.large {
    padding-inline: 0.75rem;
  }

  &.square {
    border-radius: 0.25rem;
  }
}
