$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.itemRow {
  color: $secondary-900;

  td {
    vertical-align: middle;

    span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }

    &.locationText {
      max-width: 10.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .details {
    display: flex;
    align-items: center;
    color: $secondary-600;
  }

  .imdiLogo {
    margin-right: 0.25rem;
  }

  .languageInfo {
    display: flex;
    gap: 0.5rem;
  }

  .qualification {
    display: flex;
    display: inline-block;
    max-width: 23rem;
    text-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .statistic {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    div {
      padding: 0.25rem 0.75rem;
    }

    .itemValue {
      display: block;
      color: $secondary-900;
      font-size: 1rem;
    }

    .itemLabel {
      color: $secondary-600;
      font-size: 0.875rem;
    }
  }

  .notes {
    display: inline-block;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .indicatorIcons {
    display: inline-flex;
    gap: 0.375rem;
    margin-right: 0.375rem;

    .icon {
      margin-left: 0;
      color: $secondary-300;

      &.active {
        color: $secondary-700;
      }
    }
  }

  .hints {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    .icon {
      margin-left: 0rem;
      font-size: 1.25rem;
      color: $secondary-300;

      &.active {
        color: $secondary-700;
      }
    }
  }
}
