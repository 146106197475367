$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .phoneInputs,
  .dateOfBirthInputs,
  .nameInputs {
    display: flex;
    gap: 2rem;

    & > * {
      width: 100%;
    }
  }

  .phoneInputs {
    & > :first-child {
      max-width: 7rem;
    }
  }

  .dateOfBirthLabel {
    margin-bottom: 0.25rem;
    color: $secondary-900;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .dateOfBirthInputs {
    align-items: start;
  }

  .genderLabel {
    font-weight: 400;
    color: $secondary-900;
    margin-bottom: 1.25rem;
  }

  .genderRadioButtons {
    display: flex;
    gap: 2rem;
    align-items: center;

    label {
      font-weight: 600;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
