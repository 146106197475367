$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.chatSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $secondary-200;
  font-weight: 400;

  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;

    span {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .messagesContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
    overflow-y: scroll;
    height: 100%;

    .noMessages {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      height: 100%;

      .icon {
        font-size: 3rem;
        color: $secondary-600;
      }
    }
  }

  .date {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    color: $secondary-600;
    font-size: 14px;

    .skeleton {
      display: block;
      width: 5rem;
    }
  }

  .message {
    margin-bottom: 1.5rem;

    .skeleton {
      width: 10rem;
    }

    .messageHeader {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;

      .time {
        font-weight: 400;
        color: $secondary-600;
      }
    }

    .messageBody {
      margin-left: 2rem;
    }
  }

  hr.divider {
    border: 1px solid $secondary-200;
    margin: 0rem;
  }

  .footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 1.5rem 1rem;
    background-color: white;
    border-top: 1px solid $secondary-200;

    > :first-child {
      width: 100%;
    }
  }
}
