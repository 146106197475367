$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.toggle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .chevron {
    font-size: 0.75rem;
    color: $secondary-600;
  }
}

.isLocked {
  opacity: 0.7;
}

.lockedToggle {
  cursor: default;
}

.success {
  color: $success-300;
}

.error {
  color: $error-300;
}

.info {
  color: $info-300;
}

.warning {
  color: $warning-300;
}

.primary {
  color: $primary-300;
}

.neutral {
  color: $secondary-300;
}
