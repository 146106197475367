$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.chip {
  border-radius: $border-radius-lg;
  border: 1px solid $secondary-300;
  color: $secondary-900;
  padding: 0.5rem 0.75rem;

  span {
    font-weight: 400;
  }

  &.selected {
    background-color: $primary;

    span {
      color: white;
    }
  }
}
