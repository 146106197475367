$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 2rem;

  .buttonsWrapper {
    display: flex;
    gap: 1rem;
  }

  .cancelButton {
    border-color: $error-600;
    color: $error-600;

    &:hover {
      border-color: $error-600;
      background-color: $error-600;
      color: white;
    }
  }
}
