$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.invitationLogTabSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  overflow: hidden;

  .headerInfo {
    color: $secondary-600;
    font-size: 0.875rem;
  }

  .logSkeleton {
    background-color: lightblue;
    display: flex;

    .content {
      width: 20rem;
    }
  }

  .logItemButton {
    background: none;
    border: none;
    padding: 0;
    text-align: left;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logItemDetails {
    margin-left: 3.5rem;
  }

  .logsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-left: 1px solid $secondary-200;
    margin-top: 1.5rem;
    padding-left: 1.5rem;
  }

  .logItem {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    &::before {
      content: '';
      position: absolute;
      height: 90%;
      top: 1rem;
      left: 1.2rem;
      border-left: 1px solid $secondary-200;
      z-index: 1;
    }

    &:last-child {
      &::before {
        height: 0%;
      }
    }

    .skeleton {
      width: 15rem;
    }

    .avatar {
      margin-top: 0.5rem;
      z-index: 2;

      .systemIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: $secondary-100;
      }
    }

    .logSubject {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      font-weight: 400;
      color: $secondary-900;
      padding-bottom: 0.25rem;

      strong {
        font-weight: 500;
        color: $secondary-900;
      }

      .author {
        font-weight: 500;
      }
    }

    .date {
      color: $secondary-600;
      font-weight: 400;
      font-size: 0.875rem;
    }

    .expendedIndicator {
      margin-top: 0.5rem;
      margin-left: auto;
    }

    .description {
      display: block;
      margin-top: 1rem;
    }

    .values {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      gap: 0.5rem;
    }

    .descriptionValues {
      display: flex;
      width: 100%;
      align-items: flex-start;
      margin-top: 1rem;
    }

    .descriptionOldValueWrapper {
      width: 55%;
      display: flex;
      align-items: center;
    }

    .descriptionOldValue {
      max-width: 100%;
      width: 90%;
      text-align: left;
    }

    .descriptionValue {
      width: 45%;
      text-align: left;
    }

    .descriptionArrow {
      width: 10%;
      display: flex;
      justify-content: center;
    }

    .descriptionArrow,
    .arrow {
      font-size: 0.75rem;
    }
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2.5rem;
}
.headerValues {
  display: flex;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  color: $secondary-600;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
