$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.toast {
  .body {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .content {
      display: flex;
      gap: 0.5rem;

      .success {
        color: $success-600;
        font-size: 1rem;
        padding: 0.125rem;
      }

      .error {
        color: $error-600;
        font-size: 1rem;
        padding: 0.125rem;
      }

      .message {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
      }
    }

    .button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .closeButton {
      color: $secondary-200;
    }
  }
}
