$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.radioOptions {
  padding-left: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.textArea {
  margin-top: 1rem;
}

.list,
.listTitle {
  font-size: 1.125rem;
  font-weight: 400;
  color: $secondary-900;
}

.listTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.interpreterCardWrapper {
  .interpreterCard {
    background-color: $white;
    border: 1px solid $secondary-200;
  }
}
