$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.riskFactors {
  margin-top: 3rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 2rem;
}

.riskFactors--risky {
  background-color: $error-200;
}
.riskFactors--risk_accepted {
  background-color: $warning-200;
}

.text {
  font-weight: 400;
}

.title {
  color: $secondary-600;
}

.exclamationIcon--risky,
.exclamationIcon--risk_accepted {
  margin-right: 0.5rem;
}

.exclamationIcon--risky {
  color: $error-600;
}
.exclamationIcon--risk_accepted {
  color: $warning-600;
}

.riskList {
  display: flex;
  gap: 0.5rem;
}

.icon {
  color: $secondary-700;
}

.riskItem {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  background: $white;
}
