$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.summaryView {
  .flexRow {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  hr {
    color: $secondary-200;
    opacity: 1;
    margin: 2rem 0;
  }

  .defaultBadge {
    height: fit-content;
    border: 1px solid $secondary-300;
    border-radius: $border-radius-sm;
    font-size: 0.875rem;
    padding: 2px 4px;
    margin-left: 0.5rem;
  }

  .description {
    color: $secondary-600;
  }

  .fileItem {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .fileIcon {
    width: 10px;
    padding: 0 3px;
  }

  .fileButton {
    background: none;
    border: none;
    padding: 0;
    text-align: left;
    font-weight: 500;
    text-decoration: underline;
  }

  .returnAddressLabel {
    font-weight: 600;
    padding-top: 0.5rem;
  }

  .icon {
    color: $secondary-700;
  }

  .textList {
    display: flex;
    flex-direction: column;
  }

  .filesList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
