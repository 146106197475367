$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  hr {
    margin: 0;
  }
}

.formFooter {
  display: flex;
  gap: 1rem;
  margin: 0 0 0 auto;
}

.inputRow {
  display: flex;
  gap: 1.5rem;

  > * {
    width: 100%;
  }

  .defaultIndicator {
    border: 1px solid $secondary-300;
    border-radius: $border-radius-sm;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    background-color: $white;
  }
}

.sectionTitle {
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  color: $secondary-900;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
}

.pricingTemplates {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
