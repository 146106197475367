$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.avatar {
  position: relative;

  .activeIcon {
    position: absolute;
    right: 0.125rem;
    bottom: 0.125rem;
    width: 1rem;
    height: 1rem;
    color: $success-600;
    background-color: $white;
    border-radius: 50%;
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;

    &.medium {
      height: 2.5rem;
      width: 2.5rem;
    }

    &.large {
      height: 4rem;
      width: 4rem;
    }
  }
}
