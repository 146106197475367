$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.hint {
  font-size: 0.875rem;
  color: $secondary-600;
  font-weight: 400;
}

.title {
  font-size: 0.875rem;
  font-weight: 700;
  color: $secondary-900;
}

.videoSystemWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: $white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid $secondary-400;
}

.videoSystemWrapperNoBorder {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: $white;
}

.videoSystem {
  display: flex;
  gap: 1.5rem;
}

.videoSystemUrl {
  width: 100%;
}

.banner {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: $secondary-900;

  .bannerButton {
    button {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: $secondary-900;
      padding: 0;
      border: 0;
    }
  }
}
