$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.editInvoicing {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .label {
    color: $secondary-900;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .emptyState {
    margin-top: 0.5rem;
    border: 1px solid $secondary-400;
    border-radius: $border-radius-sm;
    width: 100%;
    padding: 3rem;
    text-align: center;
  }

  .paymentMethods {
    display: flex;
    width: 100%;
  }
  
  .addNewButton,
  .newOption {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 14rem;
    padding: 3rem;
    margin-top: 1.5rem;
    margin-left: 1rem;
    border: 1px dashed $secondary-400;
    border-radius: $border-radius-md;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      border-color: $secondary-900;
    }
  }

  .addNewButton {
    margin-top: 0.5rem;
    width: 100%;
    margin-left: 0;
  }

  .dropdownPaymentMethods {
    .selectorWithButton {
      display: flex;
      width: 100%;
      gap: 0.5rem;
    }

    .selector {
      width: 100%;
    }

    .button {
      min-width: 10rem;
    }
  }

  hr {
    margin: 0;
  }

  .inputRow {
    display: flex;
    gap: 2rem;

    > * {
      width: 100%;
    }

    .defaultIndicator {
      border: 1px solid $secondary-300;
      border-radius: $border-radius-sm;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
