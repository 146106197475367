$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

thead.tableHeader {
  tr.filters {
    background-color: $secondary-100;

    th {
      border: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      vertical-align: middle;

      &::before {
        width: 0px;
      }

      &:first-of-type {
        border-bottom-left-radius: $border-radius-md;
        border-top-left-radius: $border-radius-md;
        padding-left: 0.75rem;
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        padding-right: 0.75rem;
      }

      &.idFilter {
        width: 4%;
        min-width: 6rem;

        &--stuck {
          display: grid;
          grid-template-columns: 2fr 1fr;
          gap: 2rem;
          min-width: 10rem;
        }
      }

      &.returnedFromFinanceFilter {
        width: 4%;
        min-width: 2rem;
      }

      &.dateFilter {
        width: 7%;
        min-width: 13rem;
      }

      &.languageFilter {
        width: 12%;
        min-width: 18rem;
      }

      &.typeFilter {
        width: 10%;
        min-width: 10rem;
      }

      &.ownerFilter {
        width: 20%;
        min-width: 16rem;
      }

      &.paymentMethodFilter {
        width: 12%;
        min-width: 10rem;
      }

      &.customerTemplateFilter {
        width: 12%;
        min-width: 12rem;
      }

      &.interpreterTemplateFilter {
        width: 12%;
        min-width: 12rem;
      }

      &.categoryFilter {
        width: 11%;
        min-width: 15rem;
      }

      &.buyerFilter {
        width: 10%;
        min-width: 10rem;
      }

      &.statusFilter {
        width: 10%;
        min-width: 10rem;
      }

      &.hintsFilter {
        width: 13rem;
        min-width: 13rem;

        .hints {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .icon {
            margin-left: 0rem;
            font-size: 1.25rem;
            color: $secondary-400;
            cursor: pointer;

            &.active {
              color: $secondary-700;
            }

            &.critical {
              color: $error-600;
            }
          }
        }
      }

      .notesHeader {
        width: 3rem;
        min-width: 3rem;
      }

      .datePicker {
        position: absolute;
        width: 50rem;
      }
    }
  }

  tr.headers {
    th {
      vertical-align: middle;
      font-weight: 600;
      color: $secondary-600;
    }
  }

  th:first-of-type {
    padding: $table-cell-padding-y calc($table-cell-padding-x + $row-indicator-width);
  }

  .filterButton {
    color: $secondary-400;
  
    &--active {
      color: $secondary-700;
    }
  }
}

.emptyStateContainer {
  margin: 6rem auto;
}
