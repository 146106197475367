$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/base.scss';

.form {
  padding: 2rem;
  background-color: $white;
  border-radius: $border-radius-lg;

  @include media-breakpoint-down(sm) {
    padding: 2rem 1rem;
  }

  .content {
    margin-top: 3rem;

    & > :not(:first-child) {
      margin-top: 2rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    & > :not(:first-child) {
      margin-left: 3.5rem;
    }

    .heading {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .illustration {
      width: 6rem;
      height: 6rem;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .description {
    color: $secondary-600;
    font-weight: $form-text-font-weight;
    font-size: 1.125rem;
  }
}
