$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

thead.tableHeader {
  tr.filters {
    background-color: $secondary-100;

    th {
      border: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      vertical-align: middle;
      &::before {
        width: 0px;
      }

      &:first-of-type {
        border-bottom-left-radius: $border-radius-md;
        border-top-left-radius: $border-radius-md;
        padding-left: 0.75rem;
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        padding-right: 0.75rem;
      }

      &.idFilter {
        width: 5%;
        min-width: 6rem;
      }

      &.orgNumberFilter {
        width: 10%;
        min-width: 6rem;
      }

      &.orgNameFilter {
        width: 30%;
      }

      &.ownerNameFilter {
        width: 30%;
      }
    }
  }
}

.emptyStateContainer {
  margin: 6rem auto;
}
