$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.orderSection {
  padding: 2rem;
  height: 100%;
  overflow-y: scroll;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.link,
.date {
  font-weight: 400;
  color: $secondary-600;
}

.header {
  font-size: 2rem;
  font-weight: 600;
  color: $secondary-900;
}

.category {
  font-size: 1.125rem;
  font-weight: 400;
}

.infoItems {
  display: flex;
}

.timeInterval {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.requirementsSection {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.cancelBannerText {
  font-size: 0.875rem;
  font-weight: 400;
  color: $secondary-900;

  &--bold {
    font-weight: 600;
  }
}
