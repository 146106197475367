$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.orderSection {
  padding: 2rem;
  height: 100%;
  overflow-y: scroll;

  .orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .orderTitle {
      display: flex;
      gap: 0.5rem;
      font-size: 2.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .orderDetails {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .secondaryValue {
        color: $secondary-600;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  .orderId {
    display: flex;
    gap: 0.5rem;
    color: $secondary-600;
    font-weight: 400;
  }

  .orderInfo {
    display: flex;
    margin-top: 1.5rem;

    .infoItem {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding-right: 1.5rem;

      &:not(:first-child) {
        border-left: 1px solid $secondary-200;
        padding-left: 1.5rem;
      }

      .label {
        color: $secondary-600;
        font-weight: 400;
      }

      .value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 400;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .buttons {
      display: flex;
      gap: 1rem;
    }

    .customerSwitch {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 400;
    }
  }

  .section {
    margin-top: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-weight: 400;

    &:not(:first-of-type) {
      border-top: 1px solid $secondary-200;
    }

    &.table {
      .row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 1rem;
        margin-top: 0.5rem;

        .label {
          color: $secondary-600;
        }

        .value {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .hint {
          display: block;
          color: $secondary-600;
          font-size: 0.875rem;
          font-weight: 400;
        }

        .quoteNotShown {
          color: $error-600;
        }
      }
    }

    .card {
      margin-top: 1rem;
      border: 1px solid $secondary-200;
      border-radius: $border-radius-md;
      padding: 1.25rem;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;

        &:not(:first-of-type) {
          margin-top: 1.5rem;
        }

        .label {
          color: $secondary-600;
        }

        .value {
          display: block;
        }

        .actions {
          margin-top: 1rem;
        }

        .tooltip {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.75rem;
          height: 2.75rem;
          color: $secondary-700;
        }
      }
    }

    .container {
      padding: 1.5rem;
      background-color: $secondary-50;
      border-radius: $border-radius-md;
      margin-top: 1.25rem;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.1235rem;
      font-weight: 600;
      margin-bottom: 1rem;

      .action {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
      }
    }

    .sendByPostBlock {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .coverLetter {
        color: $secondary-900;
        cursor: pointer;
      }
    }

    .notSentByPost {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1.5rem;
      width: 100%;
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 500;

      .notSentByPostIcon {
        font-size: 2.5rem;
      }
    }

    .icon {
      font-size: 1rem;
    }
  }
}
