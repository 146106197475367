$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .phoneInputs,
  .nameInputs {
    display: flex;
    gap: 2rem;

    & > * {
      width: 100%;
    }
  }

  .phoneInputs {
    & > :first-child {
      max-width: 7rem;
    }
  }

  .customerTypeTitle {
    font-weight: 400;
    color: $secondary-900;
    letter-spacing: -0.16px;
    margin-bottom: 0.8rem;
  }

  .customerType {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  .companyInformation {
    padding: 1.5rem;
    background-color: $secondary-100;
    margin-bottom: 2rem;
    border-radius: $border-radius-md;

    .title {
      font-size: 1.2rem;
      color: $secondary-900;
      font-weight: 600;
      letter-spacing: -0.18px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: -0.16px;
      line-height: 1.5rem;

      .subtitle {
        flex: 1;
        color: $secondary-600;
      }

      .value {
        flex: 1;
        color: $secondary-900;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .error {
    color: $error-600;
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.succesContainer {
  .blockBackground {
    display: flex;
    justify-content: center;
    background-color: $success-100;
    border-radius: $border-radius-md;
    height: 10rem;
    padding: 2.5rem 1.5rem;

    .innerBlockBackground {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border-radius: $border-radius-md;
      width: 20rem;
      padding: 1.25rem;
      box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.15);

      .avatar {
        background-color: $secondary-200;
        border-radius: $border-radius-md;
        height: 2.5rem;
        width: 2.5rem;
      }

      .simulatedNames {
        padding: 0.5rem 0;

        .simulatedFirstName {
          display: block;
          background-color: $secondary-200;
          border-radius: $border-radius-md;
          width: 6rem;
          height: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .simulatedLastName {
          display: block;
          background-color: $secondary-200;
          border-radius: $border-radius-md;
          width: 4rem;
          height: 0.5rem;
        }
      }
    }
  }

  .message {
    margin: 1.5rem 0 2.5rem 0;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
