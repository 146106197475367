$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.editSubtask {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 8.25rem;

  .overview {
    background-color: $secondary-100;
    border-radius: $border-radius-md;
    padding: 1rem;

    .id {
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: $secondary-900;
    }

    .description {
      display: block;
      color: $secondary-900;
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }

  .detailItemList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .selectedIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border: 2px solid $secondary-400;
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    border-radius: 50%;

    &.selected {
      background-color: $secondary-900;
      border-color: $secondary-900;
    }

    .selectedIcon {
      font-size: 0.75rem;
      color: white;
    }
  }

  .inputRow {
    display: flex;
    gap: 2rem;
  }

  .emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    color: $secondary-900;
    padding-top: 6rem;
  }

  .emptyStateIcon {
    font-size: xx-large;
    color: $secondary-600;
  }
}

.tabsContainer {
  border-bottom: 1px solid $secondary-200;
}

.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 2.5rem 3rem 2.5rem 0;
  width: 100%;
  border-top: 1px solid $secondary-200;
  background-color: $white;
}
