$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.detailsCard {
  border-radius: $border-radius-md;
  overflow: hidden;
  border: 1px solid $secondary-300;
  background-color: $white;

  hr {
    margin: 0;
  }

  .header {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    > div {
      &:first-child {
        display: flex;
        align-items: center;
      }

      > img {
        height: 4rem;
        width: 4rem;
      }
    }

    .headerContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
      
      .detailsTitle {
        color: $secondary-900;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .headerValues {
        display: flex;
        column-gap: 1rem;
        row-gap: 0.5rem;
        flex-wrap: wrap;
        color: $secondary-600;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        span {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }
  }

  .footer {
    padding: 1rem;
    display: flex;
    gap: 2.5rem;
    color: $secondary-900;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    svg {
      color: $secondary-900;
    }

    div {
      display: flex;
      flex-direction: column;
      font-weight: 400;

      .label {
        font-size: 0.875rem;
        color: $secondary-600;
      }

      .value {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 1rem;
      }
    }
  }
}
