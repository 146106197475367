$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.financeSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 3rem;
}

.financeCard {
  padding: 1.5rem;
  background-color: $secondary-50;

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .icon {
    width: 1rem;
    height: 1rem;
  }

  .totalTitle {
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
    font-weight: 600;
  }

  .totalSubtitle {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: $secondary-600;
  }

  .tags {
    display: flex;
    gap: 0.5rem;
  }

  .button {
    margin-top: 2.5rem;
  }
}

.financeDrawer {
  .tabContent {
    padding: 2rem 0;
  }

  hr {
    margin: 0;
  }

  .flexRow {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .tableHeader {
    color: $secondary-600;
    font-weight: 600;
  }

  .price {
    white-space: nowrap;
  }

  .textRight {
    display: flex;
    justify-content: flex-end;
  }

  .totalPriceRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondary-100;
    font-size: 0.875rem;
    color: $secondary-900;
    padding: 1.125rem 0.75rem;
    border-radius: 0.5rem;
    font-weight: 600;
    margin-top: -1rem;
  }
}
