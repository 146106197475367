$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.detailItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid;
  font-size: 1rem;

  &.primary {
    background-color: $white;
    border-color: $secondary-200;
  }

  &.archived {
    border-color: $secondary-200;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.selected {
    background-color: $white;
    border-color: transparent;
    box-shadow: 0 0 0 2px $secondary-900;
  }

  &.error {
    background-color: $error-100;
    border-color: $error-600;
  }

  &.warning {
    background-color: $warning-100;
    border-color: $warning-600;
  }

  &.large {
    padding: 1.25rem 1.5rem;
  }

  &.clickable {
    cursor: pointer;
  }

  .titleContainer {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .subtitle {
      display: block;
      font-size: 0.875rem;
      color: $secondary-600;
    }
  }

  .contentOpacity {
    opacity: 0.5;
  }

  .rightSection {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: auto;
  }
}
