$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.banner {
  color: $text-dark;
  font-size: 0.875rem;

  .title {
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    font-weight: 700;
    color: $secondary-900;
  }

  .body {
    color: $secondary-600;

    &.leftPad {
      margin-left: 1.8rem;
    }

    a {
      color: $text-dark;
    }
  }

  .icon {
    margin-right: 0.625rem;
    font-size: 1rem;

    &.information {
      color: $info-600;
    }
    &.success {
      color: $success-700;
    }
    &.warning {
      color: $warning-600;
    }
    &.error {
      color: $error-600;
    }
  }

  .content {
    display: flex;
    margin-top: 0.75rem;

    & > :not(:first-child) {
      margin-left: 0.75rem;
    }
  }
}
