$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@use 'sass:math';
@import '@skiwo/styles/global/variables.scss';

$_CELL_SIZE: 10rem;
$_HOVER_BG: $secondary-100;

.interpretersTimeline {
  background: $white;
  border-radius: 0.5rem;
}

.filters {
  padding: 2rem 1.5rem 3.875rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nameFilter {
  width: 14rem;
}

.qualificationFilter{
  width: 16rem;
}

.distanceFilter {
  width: 12.5rem;
}

.genderFilter {
  width: 7.5rem;
}

.twoColGrid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  overflow-x: hidden;
}

.timelineOverflow {
  position: relative;

  &::after {
    position: absolute;
    inset: 0 math.div($_CELL_SIZE, 4) 0 0;
    background-color: $white;
    content: '';
    z-index: 2;
  }
}

.timeGrid {
  display: grid;
  margin-bottom: 0.5rem;
}

.timeCell {
  text-align: center;
  transform: translateX(-50%);
  color: $secondary-400;
  font-size: 0.875rem;
  font-weight: 400;

  &:last-child {
    text-align: right;
    transform: translateX(-100%);
  }
}

.personWrapper {
  display: flex;
  gap: 1rem;
  height: 100%;
}

.personColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.personRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: $secondary-600;

  &--gap-l {
    gap: 1rem;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:last-child {
    margin: 1.5rem 0 0;
  }

  h4 {
    margin-bottom: 0;
    font-weight: 600;
  }
}

.personHeader {
  color: $secondary-900;
}

.notesBtn {
  margin: 0 0 0 auto;
}

.personCell {
  padding: 1.5rem;

  &--active {
    background-color: $_HOVER_BG;
  }
}

.personActionData {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 100%;
  right: calc(-100% - 2px);
  background-color: $_HOVER_BG;
  z-index: 2;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  .banner {
    margin: 0;
  }
}

.personActionWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flexFull {
  flex: 1 1 100%;
}

.flexSmall {
  flex: 1 1 28%;
}

.flexSquare {
  flex: 0 0 auto;
}

.scrollableCol {
  position: relative;
  overflow-x: auto;
}

.timelineGrid {
  position: relative;
  display: grid;
}

.timelineCell {
  position: relative;
  height: $_CELL_SIZE;
  border: 1px solid $secondary-200;
  border-width: 1px 1px 0 0;
}

.timeslotPill {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: math.div($_CELL_SIZE, 4);
  text-wrap: nowrap;
  border-radius: 0.25rem;
  top: math.div($_CELL_SIZE, 2);
  z-index: 1;

  &--selected {
    top: 0;
    bottom: 0;
    background-color: rgba($info-400, 0.75);
  }

  &--green {
    background-color: $success-300;
    border: 1px solid $success-500;
  }

  &--default {
    background-color: $secondary-100;
    border: 1px solid $secondary-300;
  }

  &--gray {
    background-color: $secondary-300;
    border: 1px solid $secondary-300;
  }

  &--green ~ &--gray,
  &--green ~ &--default {
    top: math.div($_CELL_SIZE, 4);
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.calendarWrapper {
  position: relative;
}

.emptyState {
  position: absolute;
  top: 1.875rem;
  left: 0;
  width: 100%;
  height: 95%;
  border-radius: 0.25rem;
  background: #d0d5dd7d;
  z-index: 2;
  text-align: center;
  padding-top: 4rem;
  font-size: 1.25rem;
  color: $secondary-700;
}
