$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    color: $secondary-900;
    background-color: $secondary-100;

    .id {
      font-size: 1.125rem;
      color: $secondary-900;
      font-weight: 600;
    }

    .language,
    .name,
    .createdDate {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .language {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .contactInfo {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.75rem;
    padding-block: 2.5rem;

    svg {
      font-size: 2.5rem;
      color: $secondary-200;
    }
    p {
      color: $secondary-500;
      font-weight: 400;
      margin: 0;
    }
  }
}

.noteTextfield {
  margin-bottom: 0.625rem;

  textarea {
    height: 7.75rem;
  }
}

.postButtons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 2.5rem;
}
