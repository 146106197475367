$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.applyInterpreterDrawer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.cardWrapper {
  padding: 1rem;
  border: 1px solid $secondary-200;
  border-radius: 0.5rem;
}

.tableTitle {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $secondary-900;
  font-size: 1.125rem;
}

.totalPriceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary-100;
  font-size: 1rem;
  color: $secondary-900;
  padding: 1.125rem 0.75rem;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-top: -1rem;
}

.totalPriceRowLoader {
  width: 10rem;
  color: $secondary-900;
}

.table {
  font-size: 1rem;
}

.travelLabel {
  color: $secondary-600;
  font-size: 0.875rem;
}

.travel {
  display: flex;
  flex-direction: column;
}

.warning {
  color: $error-600;
}

.textRight {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 2.5rem;
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 0.5rem;
  align-items: center;
  justify-content: end;
}
