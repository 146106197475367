$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    border-radius: $border-radius-md;
    color: $secondary-900;

    .id {
      font-size: 0.875rem;
      color: $secondary-400;
      font-weight: 400;
    }

    .name {
      font-weight: 700;
      font-size: 1.125rem;
    }

    .contactInfo {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .textInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      font-weight: 500;
      font-size: 1.125rem;
    }

    .description {
      font-weight: 400;
      color: $secondary-600;
      font-size: 0.875rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
