$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.sendSavedResponse {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .overview {
    background-color: $secondary-100;
    border-radius: $border-radius-md;
    padding: 1rem;

    .id {
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .description {
      display: block;
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }

  .detailsItemDetails {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .emailAddressesHeader {
    color: $secondary-600;
    font-weight: 400;
  }
  
  .emailAddresses {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .selectRowLabel {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
    color: $secondary-900;
  }

  .selectRow {
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  .selectRow > div {
    width: 100%;
  }

  .newResponseButton {
    min-width: 30%;
  }

  .dropdownButton {
    width: 100%;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
