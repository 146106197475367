$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.editOrder {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .inputRow,
  .wordCountAccessRow {
    display: flex;
    gap: 2rem;

    > div,
    > button {
      width: 100%;
    }

    input::placeholder {
      color: $secondary-900;
      opacity: 1;
    }
  }

  .wordCountAccessRow {
    > div {
      flex-grow: 0;
      width: 14rem;
    }

    > div:nth-child(2) {
      flex-grow: 1;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .loggedOutContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .phoneInputs,
    .nameInputs {
      display: flex;
      gap: 2rem;

      & > * {
        width: 100%;
      }
    }

    .phoneInputs {
      & > :first-child {
        max-width: 14rem;
      }
    }

    hr {
      margin: 0;
      color: $secondary-200;
    }

    .logoutTitle {
      color: $secondary-900;
      font-size: 1.125rem;
      margin: 0;
    }
  }
  .errorText {
    padding-top: 0.25rem;
    display: flex;
    gap: 0.25rem;
    color: $error-600;
    font-size: 0.875rem;
    font-weight: 400;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}
