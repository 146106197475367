$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.formFooter {
  display: flex;
  gap: 1rem;
  margin: 0 0 0 auto;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  :global(.alert) {
    margin: 0;
  }
}

.modalGrid {
  display: grid;
  grid-template-columns: 24% auto;
  gap: 1.5rem 0.5rem;
  padding: 1.25rem 1.5rem;
  border: 1px solid $secondary-200;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: $secondary-900;
}

.bodyCell {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.iconWarning {
  height: 1.125rem;
  width: 1.125rem;
  color: $warning-600;
  margin-right: 0.125rem;
}

.modalCheckbox {
  margin: 0.75rem;
}
