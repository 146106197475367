$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.additionalInfo {
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $secondary-600;
}

.emailInputRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.emailInput {
  width: 60%;
}

.list {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.errorMessage {
  color: $error-600;
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.sectionItemContent {
  margin-top: 2rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 2fr 0.6fr;
  width: 60%;
  gap: 1rem;
}
