$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.progress {
  transform: rotate(-90deg);
  width: 2.5rem;
  height: 2.5rem;

  .progressBar {
    fill: none;
    stroke: $primary-600;
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
  }

  .progressBarBackground {
    fill: none;
    stroke: $primary-200;
    stroke-width: 3;
    stroke-linecap: round;
  }
}