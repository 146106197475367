$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.badge {
  background-color: $secondary-100;

  span {
    line-height: 24px;
    color: $secondary-500;
  }

  &.disabled {
    background-color: $secondary-300;
  }

  &.active {
    background-color: $primary-100;
    span {
      color: $primary;
    }
  }

  &.default:hover {
    background-color: $secondary-50;
  }

  &.pointer-cursor {
    cursor: pointer;
  }
}
