$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.skeleton {
  td {
    vertical-align: middle;
  }

  .indicatorSkeleton {
    position: relative;
    padding-left: calc($table-cell-padding-x + $row-indicator-width);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: inline;
      width: $row-indicator-width;
      border-top-left-radius: $border-radius-md;
      border-bottom-left-radius: $border-radius-md;
      height: 100%;
      background-color: $secondary-200;
    }
  }
}
