$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.sectionContainer {
  width: 17.5rem;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .sectionName {
    padding-bottom: 1rem;
    color: $secondary-500;
    border-bottom: 1px solid $secondary-200;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }

  .itemsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-top: 1rem;
  }
}
