$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  max-height: 28.75rem;
  min-height: 10.5rem;
  overflow-y: auto;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;

  .noteContainer {
    padding-bottom: 2rem;

    .deleteBtn {
      color: $error-600;
    }

    .noteOptions {
      cursor: pointer;
    }

    .noteCard {
      gap: 1rem;
      padding: 1rem;
      border-radius: 0.5rem;
      color: $secondary-900;
      border: 1px solid $secondary-200;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      button {
        color: $secondary-900;
        cursor: pointer;
      }
    }

    .noteContent {
      display: flex;
      justify-content: space-between;
      color: $secondary-900;
      font-weight: 400;
    }

    .authorInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 0.875rem;
      font-weight: 400;
      color: $secondary-600;
      padding-top: 0.5rem;
    }

    .editNoteTextfield {
      background-color: $warning-100;
      padding: 1.5rem;
      border-radius: 0.5rem;

      textarea {
        height: 7.75rem;
      }

      .editButtons {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        justify-content: flex-end;

        .cancelButton {
          background-color: #fff;

          &:hover {
            background-color: $secondary-100;
          }
        }
      }
    }
  }
}
