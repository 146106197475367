$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import '@skiwo/styles/global/colours.scss';

.container {
  p {
    margin: 0;
  }

  .selectedEnterpriseContainer {
    margin-top: 1.5rem;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-top: 2.5rem;

    .phoneInputs {
      display: flex;
      flex-direction: row;
      gap: 2rem;

      & > * {
        width: 100%;
      }

      & > :first-child {
        max-width: 7rem;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }

    .emailHelpText,
    .phoneHelpText {
      color: $secondary-600;
      font-weight: 400;
      font-size: 0.875rem;
      margin-top: 0.25rem;
      line-height: 1.25rem;
    }
  }
}
