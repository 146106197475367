$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.header {
  color: $secondary-600;
  font-weight: 600;
  font-size: 0.875rem;
}

.body {
  --bs-table-striped-bg: #fff;
}

.rowName {
  font-weight: 600;
  color: $secondary-900;
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.inputTextRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $secondary-600;
}

.input {
  width: 5rem;
}

.filesList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rowNameFlex,
.fileItem {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.fileIcon {
  width: 10px;
  padding: 0 3px;
}

.fileLink {
  color: $secondary-900;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
}

.lastUpdatedDate,
.lastUpdatedAuthor {
  display: block;
  font-size: 0.875rem;
}

.lastUpdatedDate {
  color: $secondary-600;
}

.dateTag {
  font-size: 0.875rem;
}
