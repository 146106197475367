$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.trafficLightIndicator {
  content: '';
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;

  &.green {
    background-color: $success-600;
  }
  &.yellow {
    background-color: $warning-600;
  }
  &.red {
    background-color: $error-600;
  }
}
