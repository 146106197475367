$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;

  .pageButton {
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 2.375rem;
    justify-content: center;
  }

  .previousNextButton {
    display: flex;
    height: 2.375rem;
  }
}

.pages {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ellipsis {
  margin: 0 10px;
}
