$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.emptyTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.0875rem;
  color: $secondary-700;

  span {
    line-height: 2rem;
  }

  img {
    margin-bottom: -0.625rem;
  }

  .description {
    color: $secondary-400;
    line-height: 2rem;
    margin-top: 0.5rem;
  }

  button {
    margin-top: 2rem;
    font-size: 1rem !important;
  }
}
