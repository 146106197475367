$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

thead.tableHeader {
  tr.filters {
    background-color: $secondary-100;

    th {
      border: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      vertical-align: middle;
      &::before {
        width: 0px;
      }

      &:first-of-type {
        border-bottom-left-radius: $border-radius-md;
        border-top-left-radius: $border-radius-md;
        padding-left: 0.75rem;
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        padding-right: 0.75rem;
      }

      &.idFilter {
        width: 5%;
        min-width: 6rem;
      }

      &.typeFilter {
        width: 10%;
        min-width: 9rem;
      }

      &.nameFilter {
        width: 20%;
        min-width: 19rem;
      }

      &.genderFilter {
        width: 10%;
        min-width: 9rem;
      }

      &.standByFilter {
        width: 10%;
        min-width: 9rem;
      }

      &.hintsFilter {
        width: 7%;
        min-width: 10rem;

        .hints {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .icon {
            margin-left: 0rem;
            font-size: 1.25rem;
            color: $secondary-400;
            cursor: pointer;

            &.active {
              color: $secondary-700;
            }

            &.critical {
              color: $error-600;
            }
          }
        }
      }

      &.statsHeader {
        width: 25%;
      }
    }
  }

  tr.headers {
    th {
      font-weight: 600;
      color: $secondary-600;
    }
  }
}

.emptyStateContainer {
  margin: 6rem auto;
}
