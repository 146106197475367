$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  display: flex;
  flex-direction: column;

  .supplierContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .languageInput {
    display: flex;
    gap: 2rem;

    > div {
      flex: 1;
    }
  }

  .error {
    padding-top: 0.5rem;
    display: flex;
    gap: 0.25rem;
    color: $error-600;
    font-weight: 400;
    font-size: 0.875rem;

    > svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.supplierTypesLabel {
  font-weight: 400;
  font-size: 0.875rem;
  color: $secondary-900;
  letter-spacing: -0.16px;
  margin-bottom: 0.8rem;
}

.supplierType {
  display: flex;
  gap: 2rem;
  align-items: center;

  label {
    font-weight: 600;
  }
}

.newLanguageBtn {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 0 !important;
}

.infoBanner {
  padding-bottom: 1.5rem;
}

.skillStatus {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div {
    margin: 0.75rem;
  }
}

.docContainer {
  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    color: $secondary-900;
    padding-bottom: 0.5rem;
  }

  p {
    font-size: 1.125rem;
    font-weight: 400;
  }
}

.uploadFile {
  display: flex;
  flex-direction: column;
  font-weight: 400;

  .fileList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span {
      font-size: 0.875rem;
    }

    div:nth-child(2) > span:first-of-type {
      color: $secondary-900;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
.errorMessage {
  color: $error-600;
  text-align: end;
}
