$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.deleteCustomerModal {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .bold {
    font-weight: 600;
  }

  .reason {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .reasonTitle {
      color: $secondary-900;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .reasonDescription {
      color: $secondary-600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 400;
    }
  }

  .reasonOptions {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 0.75rem;
    margin-top: 2rem;
  }

  .otherReason {
    margin-top: 0.5rem;
  }

  .actionButtons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      max-width: 10.875rem;
    }
  }
}
