$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.informationSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .radioOptionsWrapper {
    padding: 1.25rem;
    background: $secondary-100;
    border-radius: 0.5rem;
  }

  .alternativeLanguageSelector {
    margin-top: 3rem;
  }

  .flexRow {
    display: flex;
    gap: 1.5rem;
  }

  .languageSelector {
    width: 50%;
  }

  .noSourceLanguageCheckbox {
    margin-top: 1.5rem;
  }

  .fileSection {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .fileList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .filePassword {
      max-width: 10rem;
    }
  }

  .wordsCountInput {
    width: 80%;
  }
  .deadline {
    width: 20%;
  }

  .subject {
    width: 50%;
  }
}
