$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.subtitle {
  font-size: 1rem;
}

.videoSolutionDrawer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .item {
    display: flex;
    gap: 0.75rem;
    color: $secondary-900;

    .content {
      width: 100%;
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      padding-top: 0.25rem;
    }

    .title {
      display: block;
      font-size: 1.125rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
    }

    .description {
      display: block;
      font-size: 1rem;
      font-weight: 400;

      .browsersWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding-top: 1.5rem;

        .browserItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          border-radius: 0.5rem;
          border: 1px solid $secondary-200;

          .browserItemContent {
            display: flex;
            gap: 0.75rem;
          }

          .downloadButton {
            color: $secondary-600;
            font-size: 0.875rem;
            padding: 0;
          }
        }
      }
    }
  }
}
