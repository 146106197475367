$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.skeleton {
  .box {
    height: 1.5rem;
    border-radius: $border-radius-sm;
  }

  .details {
    margin-top: 0.25rem;
    height: 1rem;
    border-radius: $border-radius-sm;
  }

  &.circlesContainer {
    display: inline-flex;
    justify-content: center;
    width: 100%;

    .circles {
      display: inline-flex;
      flex-direction: row;
      gap: 0.5rem;

      & > * {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    }
  }
}
