$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

.cancelledBy {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}

.actionButtons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;

  button {
    width: 9.375rem;
  }
}
