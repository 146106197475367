$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.itemRow {
  color: $secondary-900;

  td {
    vertical-align: middle;

    span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .hasIndicator {
    position: relative;
    padding-left: calc($table-cell-padding-x + $row-indicator-width);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: inline;
      width: $row-indicator-width;
      border-top-left-radius: $border-radius-md;
      border-bottom-left-radius: $border-radius-md;
      height: 100%;
    }

    &.green {
      &::before {
        background-color: $success-300;
      }
    }

    &.yellow {
      &::before {
        background-color: $warning-300;
      }
    }

    &.red {
      &::before {
        background-color: $error-300;
      }
    }

    &.orange {
      &::before {
        background-color: orange;
      }
    }

    &.lightblue {
      &::before {
        background-color: lightblue;
      }
    }
  }

  .accountStatus {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .details {
    display: block;
    color: $secondary-600;
  }

  .icon {
    color: $secondary-700;
    margin-left: 0.375rem;

    &.triangleExclamation {
      color: $error-600;
    }
  }

  .indicatorIcons {
    display: inline-flex;
    gap: 0.375rem;
    margin-right: 0.375rem;

    .icon {
      color: $secondary-300;
      margin-left: 0;

      &.active {
        color: $secondary-700;
      }
    }
  }

  .hints {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    .icon {
      margin-left: 0rem;
      font-size: 1.25rem;
      color: $secondary-300;
      &.active {
        color: $secondary-700;
      }
    }
  }

  .statistic {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    div {
      padding: 0.25rem 0.75rem;
    }

    .itemValue {
      display: block;
      color: $secondary-900;
      font-size: 1rem;
    }

    .itemLabel {
      color: $secondary-600;
      font-size: 0.875rem;
    }
  }

  .actions {
    display: flex;
  }
}
