$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.interpretersSection {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: $secondary-50;
  margin-top: 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $secondary-900;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.infoRow {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: $white;
  margin-top: 2rem;
}

.toggleRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $secondary-900;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }
}

.btnIconRight {
  width: 0.75rem;
  height: 0.75rem;
}

.btnIconLeft {
  width: 1.25rem;
  height: 1.25rem;
}

.switchLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
