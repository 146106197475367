$primary: #6941c6 !default;
$primary-100: #f0ecf9 !default;
$primary-200: #e1d9f4 !default;
$primary-300: #c3b3e8 !default;
$primary-400: #a58ddd !default;
$primary-500: #8767d1 !default;
$primary-600: #6941c6 !default;
$primary-700: #54349e !default;
$primary-800: #3f2777 !default;
$primary-900: #2a1a4f !default;
$background-color: #ffffff !default;

@import '@skiwo/styles/global/variables.scss';

.skeleton {
  display: flex;
  align-items: center;
  background-color: $secondary-50;
  border-radius: 0.5rem;
  padding: 1rem;

  .box {
    height: 0.5rem;
    min-height: 0.5rem;
    border-radius: $border-radius-sm;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding-left: 0.75rem;
  }

  .circles {
    gap: 0.5rem;

    & > * {
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
  }
}
